@import 'includes/_variables';
@import 'includes/_fontsize';
@import 'includes/_mediaqueries';

.p-404 {
    padding: 40px 0 120px;

    @include sp-layout() {
        padding: 90px 0 100px;
    }

    .text {
        margin-bottom: 40px;
        font-family: $font-yumincho;
        font-size: 14px;
        text-align: center;
    }

    .link {
        max-width: 300px;
        width: 100%;
        margin: 0 auto;
        padding: 15px 0;
        font-family: $font-yumincho;
        text-align: center;
        display: block;
        color: $color-default;
        border: 1px solid $color-default;
        position: relative;

        @include sp-layout() {
            max-width: 250px;
            font-size: 14px;
        }

        &::before {
            content: "";
            width: 6px;
            height: 6px;
            position: absolute;
            top: 50%;
            right: 35px;
            vertical-align: middle;
            border-top: 1px solid $color-default;
            border-right: 1px solid $color-default;
            transform: rotate(45deg) translateY(-50%);

            @include sp-layout() {
                right: 25px;
            }
        }
    }
}