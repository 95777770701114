@charset "UTF-8";
.p-404 {
  padding: 40px 0 120px;
}
@media only screen and (max-width: 767px) {
  .p-404 {
    padding: 90px 0 100px;
  }
}
.p-404 .text {
  margin-bottom: 40px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-size: 14px;
  text-align: center;
}
.p-404 .link {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  text-align: center;
  display: block;
  color: #383838;
  border: 1px solid #383838;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .p-404 .link {
    max-width: 250px;
    font-size: 14px;
  }
}
.p-404 .link::before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 35px;
  vertical-align: middle;
  border-top: 1px solid #383838;
  border-right: 1px solid #383838;
  transform: rotate(45deg) translateY(-50%);
}
@media only screen and (max-width: 767px) {
  .p-404 .link::before {
    right: 25px;
  }
}